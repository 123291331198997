export function secondsToDaysHoursMinutesAndSeconds (numberOfSeconds) {
    numberOfSeconds = Math.abs(numberOfSeconds)
    const values = {
        d: Math.floor(numberOfSeconds / 60 / 60 / 24),
        h: Math.floor(numberOfSeconds / 60 / 60) % 24,
        m: Math.floor(numberOfSeconds / 60) % 60,
        s: Math.floor(numberOfSeconds) % 60,
    }
    return Object.fromEntries(Object.entries(values).filter(([unit, value]) => value))
}
