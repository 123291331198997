<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getComplaintsBySupplierId')" class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            {{gridHeaderTitle}}<slot></slot>
            <div slot="filters" class="daterangepicker-wrapper" v-if="showDateRangeFilter">
                <mercur-date-picker
                    :dateRange="filters.dateRange"
                    @update="filterDateRangeUpdate"
                    :single-date-picker="false">
                </mercur-date-picker>
            </div>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            ref="grid"
            v-if="items && showGrid"
            :options="options"
            :items="filteredItems"
            :selected-rows.sync="selectedRows"
        ></data-table>

        <portal to="sidebarRight">
            <transition name="slide-fade" @after-leave="onSidebarHidden">
                <router-view
                    ref="routeview"
                    class="sidebarRight"
                    :selected-complaint="selectedComplaint"
                    :supplier-data="supplierData"
                    :supplier-details="supplierDetails"
                    :parent-route="complaintDetailParentRoute"
                ></router-view>
            </transition>
        </portal>
        <portal to="actionbar">
            <div class="ml-40 mb-40 flex">
                <mercur-button
                    v-if="isInvoicingAvailable"
                    class="btn text-uppercase"
                    @click="selectAllVisibleRows"
                >
                    Select <template v-if="selectedRows.length">none</template><template v-else>all</template>
                </mercur-button>
                <div v-if="selectedRows.length">
                    <transition name="slide">
                        <div>
                            <template v-if="isInvoicingAvailable && isAllowedTo('SupplierCentral/addSupplierInvoice')">
                                <mercur-button
                                    class="btn btn-raised btn-accent text-uppercase"
                                    @click="invoicingActive = true"
                                    :disabled="isLoading"
                                >Start invoicing ({{totalAmount | asMoney(selectedOrderlinesCurrencyCode)}})g</mercur-button>
                            </template>
                        </div>
                    </transition>
                </div>
            </div>
        </portal>
        <invoicing-popup
            :invoicing-active.sync="invoicingActive"
            :selected-rows="selectedRows"
            invoice-type="credit"
            v-if="isAllowedTo('SupplierCentral/addSupplierInvoice')"
        ></invoicing-popup>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import moment from 'moment'
import GridHeader from '@/components/GridHeader'
import DataTable from '@/components/DataTable'
import InvoicingPopup from '../../../components/orderlines/InvoicingPopup'
import { secondsToDaysHoursMinutesAndSeconds } from '../../../components/utils/DateTimeUtils'
import { addBusinessDaysFromHours, getDeepProperty } from '../../../components/utils/Utils'

const now = moment()

export default {
    name: 'ComplaintsOverview',
    components: { InvoicingPopup, DataTable, GridHeader },
    props: {
        gridUrl: {
            default: CONFIG.API.ROUTES.SUPPLIERS.COMPLAINTS.OVERVIEW,
        },
        gridOptions: {
            default: () => {
                return {
                    columns: {
                        'Time': {
                            field: 'dateUpdated',
                            checkboxSelection: true,
                        },
                        'Order': {
                            field: 'orderNumber',
                            valueGetter: ({ data }) => `${data.orderNumber} - ${data.orderLineNumber}`,
                        },
                        'Product': {
                            field: 'productName',
                        },
                        'Status': {
                            field: 'complaintStatus',
                            colId: 'complaintStatus',
                        },
                        'Type': {
                            field: 'complaintType',
                            valueGetter: ({ data }) => data.complaintType || '-',
                        },
                        'Category': {
                            field: 'complaintCategory',
                            valueGetter: ({ data }) => data.complaintCategory || '-',
                        },
                        'Costs': {
                            field: 'supplierComplaintCosts',
                        },
                        'Shared': {
                            field: 'dateShared',
                            valueFormatter ({ value, data }) {
                                if (data.dateShared !== null) {
                                    return moment(value).format('L LT')
                                } else {
                                    return '-'
                                }
                            },
                        },
                        'SLA': {
                            colId: 'slaTime',
                            valueGetter ({ data }) {
                                if (!data.dateShared) {
                                    return null
                                }

                                return addBusinessDaysFromHours(data.slaHours, data.dateUpdated).diff(now) / 1000 / 60 / 60
                            },
                            valueFormatter ({ value }) {
                                let timeLeft = secondsToDaysHoursMinutesAndSeconds(value * 60 * 60)
                                delete timeLeft.s

                                return (value < 0 ? '-' : '') + Object.entries(timeLeft).map(([unit, value]) => `${value}${unit}`).join(' ')
                            },
                            cellClass ({ value }) {
                                if (value < 0) {
                                    return 'error'
                                }
                                if (value < 1) {
                                    return 'warning'
                                }

                                return 'green'
                            },
                        },
                        'Updated': {
                            field: 'dateUpdated',
                            valueFormatter ({ value }) {
                                return moment(value).format('L LT')
                            },
                        },
                    },

                    sortModel: [{
                        colId: 'dateUpdated',
                        sort: 'desc',
                    }],
                }
            },
        },
        getComplaintDetailRoute: {
            type: [Function, null],
        },
        getComplaintDetailParentRoute: {
            type: [Function, null],
        },
        isGlobalOverview: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            url: null,
            items: null,
            showGrid: true,
            selectedComplaint: null,
            selectedRows: [],
            invoicingActive: false,
            isInvoicingAvailable: false,
            selectedComplaintId: null,
            supplierDetails: {
                currency: 'EUR',
            },
            filters: {
                search: '',
                dateRange: {
                    startDate: moment(),
                    endDate: moment(),
                },
            },
            dateRangeFrom: null,
            dateRangeTo: null,
            status: null,
        }
    },
    computed: {
        gridHeaderTitle () {
            return this.isInvoicingAvailable ? 'To credit invoice' : `Complaints`
        },
        filteredItems () {
            if (this.dateRangeFrom && this.dateRangeFrom.isValid() && this.dateRangeTo && this.dateRangeTo.isValid()) {
                return this.items.filter(item => moment(item.dateUpdated).isBetween(this.dateRangeFrom.startOf('day'), this.dateRangeTo.endOf('day')))
            }
            return this.items
        },
        isLoading () {
            return this.items === null
        },
        parsedDateRange: {
            get () {
                if (this.dateRangeFrom === null || this.dateRangeTo === null) {
                    return ''
                }
                if (!this.dateRangeFrom.isValid() || !this.dateRangeTo.isValid()) {
                    return ''
                }

                return this.formatDate(this.dateRangeFrom, true) + ' - ' + this.formatDate(this.dateRangeTo, true)
            },
            set (value) {
                if (typeof value !== 'string') {
                    return
                }

                let [start, end] = value.split(' - ')

                start = moment(start)
                end = moment(end)

                if (start.isValid()) {
                    this.dateRangeFrom = start
                }
                if (end.isValid()) {
                    this.dateRangeTo = end
                }
            },
        },

        complaintDetailRoute () {
            if (this.getComplaintDetailRoute) {
                return this.getComplaintDetailRoute
            }

            return (complaint) => {
                return {
                    name: this.isInvoicingAvailable ? 'ToCreditInvoiceComplaintDetail' : 'ComplaintDetail',
                    params: {
                        supplierId: this.supplierId,
                        complaintSupplierId: this.supplierId,
                        complaintId: complaint.complaintId,
                    },
                }
            }
        },
        complaintDetailParentRoute () {
            if (this.getComplaintDetailParentRoute) {
                return this.getComplaintDetailParentRoute()
            }
            return this.getDefaultComplaintDetailParentRoute()
        },
        options () {
            const gridOptions = {
                ...this.gridOptions,
            }

            gridOptions.columns['Order'].link = (value, data) => {
                return this.complaintDetailRoute(data)
            }

            gridOptions.columns['Order'].cellClass = this.getCellClass
            gridOptions.columns['Costs'].valueGetter = ({ data, context }) => {
                if (data.supplierComplaintCosts.length && data.supplierComplaintCosts.length !== 0) {
                    return this.$options.filters.asMoney(data.supplierComplaintCosts[0], data.supplierComplaintCosts[1])
                }
                if (data.supplierComplaintCosts[1] !== 'EUR' && data.supplierComplaintCosts.length !== 0) {
                    const costs = this.$store.getters['finance/convertValueObjectTo']({ currency: data.complaintCosts[1], value: data.complaintCosts[0] }, this.supplierDetails.currency)
                    return this.$options.filters.asMoney(costs.value, costs.currency)
                }
                return data.supplierComplaintCosts
            }

            gridOptions.defaultColDef = {
                sortable: true,
            }

            return gridOptions
        },
        showDateRangeFilter () {
            return this.isInvoicingAvailable || this.status === 'approved'
        },
        totalAmount () {
            return this.selectedRows.flatMap(data => data.supplierComplaintCosts.value).reduce((total, cost) => Number(total) + Number(cost), 0) * -1
        },
        selectedOrderlinesCurrencyCode () {
            const foundCurrencyRow = this.selectedRows.find(data => data.supplierComplaintCosts.currency)
            return foundCurrencyRow ? foundCurrencyRow.currency : 'EUR'
        },
    },
    watch: {
        'filters.search' (value) {
            this.$refs.grid.gridApi.setQuickFilter(value)
        },
        '$route.params': {
            handler (params) {
                const isInvoicing = this.$route.matched.some(route => route.name === 'ToCreditInvoices')
                const status = isInvoicing ? 'approved' : params.status
                this.selectedComplaintId = params.complaintId
                this.$set(this, 'status', isInvoicing ? 'approved' : params.status)
                this.$set(this, 'isInvoicingAvailable', isInvoicing)
                try {
                    this.gridOptions.columns['Status'].hide = !this.isGlobalOverview && status !== 'pending'
                    this.gridOptions.columns['Time'].hide = !isInvoicing
                    if (this.gridOptions.columns['Shared']) {
                        this.gridOptions.columns['Shared'].hide = isInvoicing
                    }

                    this.gridOptions.columns['SLA'].hide = typeof status !== 'undefined' && (isInvoicing || status === 'pending' || status === 'approved')
                    this.gridOptions.columns['Updated'].hide = isInvoicing
                    this.$refs.grid.gridOptions.api.redrawRows()
                    this.$bus.$once('sidebar-hidden', () => {
                        this.$nextTick(() => {
                            setTimeout(() => {
                                this.$refs.grid.gridOptions.api.sizeColumnsToFit()
                            }, 100)
                        })
                    })
                } catch (e) { }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        onSidebarHidden () {
            this.$bus.$emit('sidebar-hidden')
        },
        getCellClass ({ data }) {
            const classes = ['ag-grid__cell', 'ag-grid__cell--small']
            if (data.complaintId === this.selectedComplaintId) {
                classes.push('ag-grid__cell--strong')
            }
            return classes
        },
        filterDateRangeUpdate (value) {
            if (!value || value.dateRangeFrom === null || value.dateRangeTo === null) {
                this.dateRangeFrom = null
                this.dateRangeTo = null

                return
            }
            this.dateRangeFrom = moment(value.startDate)
            this.dateRangeTo = moment(value.endDate)
        },
        handleManualDateInput ($event) {
            this.parsedDateRange = $event.target.value
            $event.target.blur()
            this.$refs.dateRangePicker.open = false
        },
        formatDate (date, allDay) {
            return date.format(allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm')
        },

        selectAllVisibleRows () {
            this.$refs.grid.selectAllVisibleRows()
        },

        getDefaultComplaintDetailParentRoute () {
            const route = {
                name: this.$route.matched.some(route => route.name === 'ToCreditInvoices') ? 'ToCreditInvoices' : 'SupplierComplaints',
            }

            if (!this.isSupplier && this.supplierId) {
                if (!route.params) {
                    route.params = {}
                }
                route.params.supplierId = this.supplierId
            }

            return route
        },
        setGridUrl () {
            this.url = this.gridUrl
                .replace('{supplierId}', this.supplierId)
                .replace('{complaintStatus}', this.status)
            this.addJob(this.url)
            this.$set(this, 'items', null)
            this.$api.post(this.url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.items = data.data.items
            }).finally(() => {
                this.finishJob(this.url)
            })
        },
    },

    created () {
        this.setGridUrl()
        this.$watch(() => [this.status, this.gridUrl], {
            handler () {
                this.showGrid = false
                this.items = null

                setTimeout(() => {
                    this.showGrid = true

                    setTimeout(() => {
                        this.setGridUrl()
                    }, 500)
                }, 500)
            },
        })

        this.$bus.$on('refreshComplaintTable', () => {
            this.setGridUrl()
        })

        if (this.supplierId) {
            this.$store.dispatch('suppliers/fetchSupplierLocations', this.supplierId).then((locations) => {
                const locationWithCurrency = locations.find(location => getDeepProperty('financeSettings.currency', location))
                this.$set(this.supplierDetails, 'currency', locationWithCurrency ? locationWithCurrency.financeSettings.currency : 'EUR')
                if (this.$refs.grid) {
                    this.$refs.grid.gridApi.refreshCells()
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped>
    .complaint-comment {
        width: 90vw;
        max-width: 500px;
    }
    .attachment-dialog {
        width: 90vw;
        max-width: 500px;
    }
    .daterangepicker-wrapper {
        width: 240px;
        /deep/ .daterangepicker.opensright {
            top: 50px;
        }
    }
</style>
